.formContainer{
    display: block; 
    flex: 1;
    align-items: center;
  }
  
    form {
        display: flex;
        margin-left: 25%;
        flex-direction: column;
        gap: 20px;
        width: 400px;
    }
        input, textarea{
            padding: 20px;
            background-color: transparent;
            color: white;
            border-radius: 5px;
            margin: 10px;
  
        }
       
  
        button{
            padding: 20px;
            border: none;
            background-color: orange;
            cursor: pointer;
        }
  
      