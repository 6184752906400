.app__aboutus {
    position: relative;
    background: linear-gradient(180deg, #0c0c1d, #111132);

}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-overlay img {
    width: 391px;
    height: 415px;
    z-index: 0;
}

.app__aboutus-content {
    width: 100%;
    z-index: 2;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align:center;
}

.app__aboutus-content_about p {
    margin: 5rem 0;
    /* color: var(--color-grey); */
}

@media screen and (max-width: 2000px) {
    .app__aboutus-content_about p {
        margin: 4rem 2rem 2rem 1rem;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }
    .app__aboutus-content_about p {
        margin: 3rem 0 0 1rem;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}