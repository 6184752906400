.header {
    display: block;
    
}

.app__header-h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 500;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    /* line-height: 65px; */
    /* font-size: 20px; */
}
.app__header-img img {
    width: 50%;
 
}


@media screen and (max-width: 2000px) {
    .app__header-h1 {
        font-size: 65px;
        line-height: 55px;
    }
    .app__wrapper_img img {
        height: 400px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 57px;
        line-height: 52px;
    }
    .app__wrapper_img img {
        width: 250px;
        height: 400px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 48px;
        line-height: 46px;
    }
     .app__wrapper_img img {
        height: 260px;
    }

}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 32px;
        line-height: 32px;
    }


}