.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(228, 217, 7, 0.1), 0 15px 12px rgba(224, 146, 11, 0.02);
  }
  #shadow-effect p {
    font-family: inherit;
    font-size: 17px;
    line-height: 1.5;
    margin: 0 0 17px 0;
    font-weight: 300;
  }
  .img-circle {
    border-radius: 50%;
    vertical-align: middle;
    max-width: 90px;
    min-height: 90px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
  }
  .reviews{
    width: 100%;
  }
  #clients-reviews {
    margin-top: 6%;
  }
  .review-name {
    margin: -17px auto 0;
    display: table;
    width: auto;
    /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
    background: linear-gradient(100deg, #FF8066, #FF918D);
    /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
    padding: 9px 35px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
    box-shadow: 0px 9px 18px rgba(0,0,0,0.12), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #clients-reviews .item {
    text-align: center;
    padding: 50px;
    margin-bottom: 0px;
    opacity: 0.45;
    color: black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
  }
  #clients-reviews .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
    object-fit: cover;
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #56423D;
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  
  }
  .owl-carousel .owl-dots .owl-dot{ 
    display: inline-block;
    cursor: pointer;
   
  }
  .owl-carousel .owl-dots .owl-dot span{ 
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 250ms ease-out 0s;

  } 


  @media screen and (min-width: 2000px) {
    #clients-reviews .item {
     
      justify-content: center;
      text-align: center;
      height: 570px;
      width: 520px;
      overflow: hidden;
      margin-bottom: 35px;
      font-size: smaller;
    }
   
   
  }
  
  @media screen and (max-width: 1150px) {
    .reviews{
      width: 100%;
      height: 100%;
      font-size: xx-large;
      font-family: var(--font-alt);
    }

    #clients-reviews .item {
      margin-top: 0;
       justify-content: center;
       text-align: center;
       height: 470px;
       width: 470px;
       overflow: hidden;
       font-size: x-large;
     }

     .title{
      font-size: x-large;
    }
    .img-circle{
      margin-bottom: 5px;
    }
    .sectionTitle{
      margin-bottom: 25px;
    }
    
  
  }
  
  @media screen and (max-width: 850px) {
    .reviews{
      width: 100%;
      font-size: x-large;
      font-family: var(--font-alt);
    }
    
    #clients-reviews .item {
       justify-content: center;
       text-align: center;
       height: 480px;
       width: 450px;
       overflow: hidden;
       line-height: 28px;
       margin-bottom: 15px;
       font-size: large;
  
     }
     .title{
      font-size: larger;
     }
  }
  
  @media screen and (max-width: 650px) {
    .reviews{
      width: 100%;
      font-size: x-large;
      font-family: var(--font-alt);
    }
    #clients-reviews .item {
      justify-content: center;
      text-align: center;
      height: 60vh;
      width: 70vw;
      line-height: 24px;
      font-size: large;
      margin: 5px;
    }
    .title{
      font-size: x-large;
    }
    .img-circle{
      margin-bottom: 5px;
    }
    .sectionTitle{
      margin-bottom: 25px;
    }
  
  }
  
  @media screen and (max-width: 450px) {
    .reviews{
      width: 100%;
      
    }
    #clients-reviews .item {
     
      justify-content: center;
      text-align: center;
      height: 60vh;
      width: 80vw;
      font-size: small;
      margin-bottom: 15px;
    }
    .title{
      font-size: 14px;
      line-height: 15px;
    }
  
  }
  
  